"use client";

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './tooltip.module.scss';

//When the user hovers over the tooltip trigger icon, show the tooltip bubble
//When the user leaves the icon, start a timeout to hide the tooltip (gives them a chance to move to the tooltip bubble)
//If the user hovers over the tooltip bubble before the timeout is up, stop the timeout
//If the user leaves the tooltip bubble, start the timeout again

const ToolTip = ({
  id,
  description,
  direction = 'down'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const tooltipRef = useRef(null); // Reference for the tooltip
  let theTimeout = useRef(null); // Persisting timeout across renders

  useEffect(() => {
    //if the 'ontouchstart' property is in the window object, it's a touch device
    setIsTouchDevice('ontouchstart' in window);
  }, []);
  const clearTooltipTimeout = () => {
    clearTimeout(theTimeout.current);
  };
  const handleMouseEnter = () => {
    clearTooltipTimeout();
    setIsVisible(true);
  };
  const handleTooltipMouseEnter = () => {
    clearTooltipTimeout(); // Clear timeout when mouse enters tooltip
  };
  const handleTooltipMouseLeave = () => {
    theTimeout.current = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // Set timeout when mouse leaves tooltip
  };
  const handleMouseLeave = () => {
    theTimeout.current = setTimeout(() => {
      setIsVisible(false);
    }, 1000);
  };
  const handleClick = e => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };
  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); //run once when the component mounts.

  const tooltipClasses = classNames({
    [styles.tooltip]: true,
    [styles.isUpwards]: direction === 'up',
    [styles.isDownwards]: direction === 'down',
    [styles.isVisible]: isVisible
  });
  return <span className={styles.tooltipWrapper} data-sentry-component="ToolTip" data-sentry-source-file="index.jsx">
			<span className={styles.icon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={!isTouchDevice ? handleClick : undefined} //if it's a touch device, don't add the click handler
    onTouchStart={isTouchDevice ? handleClick : undefined} onFocus={handleMouseEnter} onBlur={handleMouseLeave} tabIndex="0" aria-describedby={`tooltip-${id}`}>
				i
			</span>
			<span className={tooltipClasses} id={`tooltip-${id}`} aria-hidden={!isVisible} ref={tooltipRef} onMouseEnter={handleTooltipMouseEnter} onMouseLeave={handleTooltipMouseLeave}>
				<p dangerouslySetInnerHTML={{
        __html: description
      }}></p>
			</span>
			<noscript>
				<span className={`${styles.tooltip} ${direction === 'up' ? styles.isUpwards : styles.isDownwards} ${styles.isVisible}`}>
					<p dangerouslySetInnerHTML={{
          __html: description
        }}></p>
				</span>
			</noscript>
		</span>;
};
ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['up', 'down'])
};
export default ToolTip;
import PropTypes from "prop-types";
/*
	As we build out these pages Im noticing a need for a generic flexbox container.
*/
const FlexContainer = ({
  Element = "div",
  wrap = "nowrap",
  direction = "row",
  gap = "var(--spacing-xxs)",
  style = null,
  children,
  ...restProps
}) => {
  const flexStyles = {
    display: "flex",
    flexDirection: direction,
    flexWrap: wrap,
    gap: gap,
    ...style
  };
  return <Element style={flexStyles} {...restProps} data-sentry-element="Element" data-sentry-component="FlexContainer" data-sentry-source-file="index.jsx">
			{children}
		</Element>;
};
FlexContainer.propTypes = {
  Element: PropTypes.string,
  children: PropTypes.any.isRequired,
  direction: PropTypes.string,
  gap: PropTypes.string,
  props: PropTypes.any,
  style: PropTypes.object,
  wrap: PropTypes.string
};
export default FlexContainer;
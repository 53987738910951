const customStyles = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: "var(--color-component-background-2)",
		borderWidth: "1.5px",
		borderColor: state.isFocused || state.isSelected ? "var(--color-border-active)" : "var(--color-border)",
		boxShadow: state.isFocused ? `0 0 0 1px var(--color-focus-state-background)` : "none",
		color: "var(--color-text)",
		"&:hover": {
			borderColor: "var(--color-border-active)",
		}
	}),
	clearIndicator: provided => ({
		...provided,
		color: "var(--color-text)"
	}),
	indicatorSeparator: provided => ({
		...provided,
		backgroundColor: "var(--color-text)"
	}),
	dropdownIndicator: provided => ({
		...provided,
		color: "var(--color-text)"
	}),
	menu: provided => ({
		...provided,
		background: "var(--color-site-background)",
		zIndex: "50"
	}),
	placeholder: provided => ({
		...provided,
		color: "var(--color-text)"
	}),
	multiValue: provided => ({
		...provided,
		backgroundColor: "var(--color-component-background)",
		".option-count": {
			display: "none"
		}
	}),
	input: provided => ({
		...provided,
		fontSize: "16px"
	}),
	multiValueRemove: provided => ({
		...provided,
		"&:hover": {
			backgroundColor: "var(--color-grey-200)",
			color: "var(--color-text)"
		}
	}),
}
export default customStyles;
import React from 'react';
import ToolTip from 'components/tooltip';
import checkboxFilterStyles from "./perspectives-checkbox-filter.module.scss";
export interface IPerspectivesCheckboxFilter {
  filter: {
    label: string;
    value: string;
    description?: string;
  };
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedMediaType: string[];
  toolTipDirection?: 'up' | 'down';
  tooltipID?: string;
}
export const PerspectivesCheckboxFilter = ({
  filter,
  handleCheckboxChange,
  selectedMediaType,
  tooltipID,
  toolTipDirection
}: IPerspectivesCheckboxFilter) => {
  return <div className={checkboxFilterStyles.filter} data-sentry-component="PerspectivesCheckboxFilter" data-sentry-source-file="index.tsx">
			<input className={checkboxFilterStyles.filterInput} type="checkbox" id={`filter-${filter.value}`} name={`filter-${filter.value}`} value={filter.value} onChange={handleCheckboxChange} checked={selectedMediaType.includes(filter.value)} />
			<label htmlFor={`filter-${filter.value}`} className={checkboxFilterStyles.filterLabel}>
				{filter.label}
			</label>
			{!!filter.description && !!tooltipID && <ToolTip description={filter.description} id={tooltipID} direction={toolTipDirection || "up"} />}
		</div>;
};
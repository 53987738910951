import React from 'react';
import PropTypes from 'prop-types';
import styles from "./pagination-controls.module.scss";
import ChevronL from "assets/icons/chevron-l.svg";
import ChevronR from "assets/icons/chevron-r.svg";
import Button from 'components/controls/buttons';
const PaginationControls = ({
  offset,
  handlePaginationChange,
  perPage,
  totalResults
}) => {
  const backButton = <Button appearance='secondary' aria-label="Previous Page" value={-perPage} className={styles.paginationButton} onClick={handlePaginationChange} onKeyDown={event => event.key === 'Enter' && handlePaginationChange(event)}>
			<ChevronL />
		</Button>;
  const forwardButton = <Button appearance='secondary' aria-label="Next Page" value={perPage} className={styles.paginationButton} onClick={handlePaginationChange} onKeyDown={event => event.key === 'Enter' && handlePaginationChange(event)}>
			<ChevronR />
		</Button>;
  const buttonTemplate = (pageNumber, currentPage) => {
    return <Button appearance='secondary' aria-label={`Go To Page ${pageNumber + 1}`} key={pageNumber} disabled={pageNumber === currentPage} value={(pageNumber - currentPage) * perPage} className={styles.paginationButton} onClick={handlePaginationChange} onKeyDown={event => event.key === 'Enter' && handlePaginationChange(event)} data-sentry-element="Button" data-sentry-component="buttonTemplate" data-sentry-source-file="index.jsx">
				{pageNumber + 1}
			</Button>;
  };
  const maxPages = (totalPages, currentPage, numberOfButtons) => {
    return Math.min(totalPages, currentPage + numberOfButtons);
  };
  const generateButtons = (offset, perPage) => {
    const currentPage = Math.floor(offset / perPage);
    const lastPage = Math.ceil(totalResults / perPage);
    const arrayOfButtons = [];
    if (currentPage >= 2) {
      for (let i = currentPage - 2; i < maxPages(lastPage, currentPage, 3); i++) {
        arrayOfButtons.push(buttonTemplate(i, currentPage));
      }
    } else if (currentPage === 1) {
      for (let i = currentPage - 1; i < maxPages(lastPage, currentPage, 4); i++) {
        arrayOfButtons.push(buttonTemplate(i, currentPage));
      }
    } else if (currentPage === 0) {
      for (let i = currentPage; i < maxPages(lastPage, currentPage, 5); i++) {
        arrayOfButtons.push(buttonTemplate(i, currentPage));
      }
    }
    return arrayOfButtons;
  };
  return <div className={styles.pageControls} data-sentry-component="PaginationControls" data-sentry-source-file="index.jsx">
			{offset > 0 ? backButton : <div className={styles.buttonSpacer} />}
			{generateButtons(offset, perPage)}
			{offset + perPage < totalResults ? forwardButton : <div className={styles.buttonSpacer} />}
		</div>;
};
PaginationControls.propTypes = {
  offset: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  perPage: PropTypes.number,
  totalResults: PropTypes.number
};
export default PaginationControls;
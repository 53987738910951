import PropTypes from "prop-types";
import styles from "./equal-width-columns.module.scss";
const EqualWidthColumns = ({
  children,
  withDivider = false,
  fullWidth = false
}) => {
  const classString = `${styles.equalWidthColumns}
		${fullWidth ? "" : " productive-component"}
		${withDivider ? ` ${styles.withDivider}` : ""}
		${fullWidth ? ` ${styles.fullWidth}` : ""}`;
  return <section className={classString} data-sentry-component="EqualWidthColumns" data-sentry-source-file="index.jsx">
			{children.map((columnContent, i) => {
      return <div
      // eslint-disable-next-line react/no-array-index-key
      key={`column-${i}`}>
						{columnContent}
					</div>;
    })}
		</section>;
};
EqualWidthColumns.propTypes = {
  children: PropTypes.any,
  withDivider: PropTypes.bool,
  fullWidth: PropTypes.bool
};
export default EqualWidthColumns;
import PropTypes from "prop-types";
import ComponentHeader from "components/headers/component-header";
import styles from "./page-section.module.scss";
/*
	Generic "Section" component that includes Title, Description, Link + standard "component" spacing.
	TODO: Revist need for "ShelfSection" if we have this component.
*/
const PageSection = ({
  title = null,
  description = null,
  link = null,
  children,
  hasClampedWidth = false,
  hasDivider = false,
  ...restProps
}) => {
  const sectionId = title ? title.toLowerCase().replace(/\s/g, '-') : null;
  const classes = hasClampedWidth ? styles.clampedWidthWrapper : styles.wrapper;
  return <section id={sectionId} className={classes} {...restProps} data-sentry-component="PageSection" data-sentry-source-file="index.jsx">
			{title || description || link ? <ComponentHeader hasClampedWidth={!hasClampedWidth} //prevent clamped width from being doubley applied to the heading.
    description={description} title={title} link={link} /> : null}
			<>{children}</>
			{hasDivider && <hr className={styles.divider} />}
		</section>;
};
PageSection.propTypes = {
  hasClampedWidth: PropTypes.bool,
  children: PropTypes.any,
  description: PropTypes.string,
  link: PropTypes.object,
  title: PropTypes.string,
  hasDivider: PropTypes.bool
};
export default PageSection;
import React from 'react';
import { PerspectivesCheckboxFilter } from 'components/controls/filters/perspectives-checkbox-filter';
import SelectComponent from "components/controls/select-component/";
import checkboxFilterStyles from "components/controls/filters/perspectives-checkbox-filter/perspectives-checkbox-filter.module.scss";
import FlexContainer from 'components/containers/flex-container';
import ClearableFilters from 'components/controls/filters/clearable-filters';
import EqualWidthColumns from "components/containers/equal-width-columns";
import PageSection from 'components/containers/page-section';
import type { Props } from 'react-select';
const mediaTypeFilters = [{
  value: "editorial",
  label: "Articles"
}, {
  value: "video",
  label: "Videos"
}, {
  value: "audio",
  label: "Audio"
}];
export const Filters = ({
  selectedMediaType,
  handleCheckboxChange,
  topicFilters,
  departmentFilters,
  selectedTopic,
  selectedDepartment,
  handleClearAll,
  handleClearTopic,
  handleClearDepartment,
  handleTopicSelectChange,
  handleDepartmentSelectChange,
  handleRemoveSelection
}: IFiltersProps) => {
  return <PageSection hasClampedWidth={true} data-sentry-element="PageSection" data-sentry-component="Filters" data-sentry-source-file="_filters.tsx">
			<h3>Filter by:</h3>

			<EqualWidthColumns fullWidth={true} data-sentry-element="EqualWidthColumns" data-sentry-source-file="_filters.tsx">
				<SelectComponent fieldName="Topic" options={topicFilters} isClearable={true} onChange={handleTopicSelectChange} value={topicFilters.find(topic => topic?.value.toLowerCase() === selectedTopic) || ""} theID={topicFilters.find(id => id?.value.toLowerCase() === selectedTopic)} id="topic-select" data-sentry-element="SelectComponent" data-sentry-source-file="_filters.tsx" />
				<SelectComponent fieldName="Department" options={departmentFilters} isClearable={true} onChange={handleDepartmentSelectChange} value={departmentFilters.find(department => department?.value.toLowerCase() === selectedDepartment) || ""} theID={departmentFilters.find(id => id?.value.toLowerCase() === selectedDepartment)} id="department-select" data-sentry-element="SelectComponent" data-sentry-source-file="_filters.tsx" />
			</EqualWidthColumns>

			<section className={checkboxFilterStyles.container}>
				<fieldset className={checkboxFilterStyles.fieldset}>
					<legend className={checkboxFilterStyles.legend}>Media Type</legend>
					<div className={checkboxFilterStyles.filters}>
						{mediaTypeFilters.map(filter => <PerspectivesCheckboxFilter key={filter.value} filter={filter} handleCheckboxChange={handleCheckboxChange} selectedMediaType={selectedMediaType} toolTipDirection='up' tooltipID={filter.value} />)}
					</div>
				</fieldset>
				<FlexContainer gap="var(--spacing-micro)" wrap="wrap" data-sentry-element="FlexContainer" data-sentry-source-file="_filters.tsx">
					{selectedTopic && <ClearableFilters handleClearAll={handleClearAll} handleClose={handleClearTopic} selectedValues={[{
          label: selectedTopic,
          value: selectedTopic
        }]} />}
					{selectedDepartment && <ClearableFilters handleClearAll={handleClearAll} handleClose={handleClearDepartment} selectedValues={[{
          label: selectedDepartment,
          value: selectedDepartment
        }]} />}
					<ClearableFilters handleClearAll={handleClearAll} handleClose={handleRemoveSelection} selectedValues={mediaTypeFilters.filter(mediaFilter => selectedMediaType.includes(mediaFilter.value))} data-sentry-element="ClearableFilters" data-sentry-source-file="_filters.tsx" />
				</FlexContainer>
			</section>
		</PageSection>;
};
interface IFiltersProps {
  selectedMediaType: any[];
  // eslint-disable-next-line no-unused-vars
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  topicFilters: any[];
  departmentFilters: any[];
  selectedTopic: string;
  selectedDepartment: string;
  handleClearAll: () => void;
  handleClearTopic: () => void;
  handleClearDepartment: () => void;
  handleTopicSelectChange: Props["onChange"];
  handleDepartmentSelectChange: Props["onChange"];
  // eslint-disable-next-line no-unused-vars
  handleRemoveSelection: (item: any) => void;
}
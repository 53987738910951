/* TODO: Move out of /containers and to /Perspectives or something */
import PropTypes from "prop-types";
import styles from "./media-grid.module.scss";
const MediaGrid = ({
  children
}) => {
  return <div className={styles.grid} data-sentry-component="MediaGrid" data-sentry-source-file="index.jsx">
			{children}
		</div>;
};
MediaGrid.propTypes = {
  children: PropTypes.any
};
export default MediaGrid;
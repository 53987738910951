import PropTypes from "prop-types";
import Select, { components } from 'react-select';
import SelectOption from './option';
import customStyles from './customStyles';
import styles from "./select-component.module.scss";
import ScreenReaderOnly from "components/accesibility/screen-reader-only";
import { useId } from 'react';
const NumericInput = props => <components.Input {...props} type="number" data-sentry-element="unknown" data-sentry-component="NumericInput" data-sentry-source-file="index.jsx" />;
const SelectComponent = ({
  showFieldName = true,
  fieldName,
  id,
  options,
  isNumeric = false,
  isSearchable = true,
  isMulti = false,
  ...restProps
}) => {
  // Generate a unique ID if not provided
  const uniqueId = useId();
  const inputId = id || `select-${uniqueId}`;
  const components = isNumeric ? {
    Option: SelectOption,
    Input: NumericInput
  } : {
    Option: SelectOption
  };
  return <div data-sentry-component="SelectComponent" data-sentry-source-file="index.jsx">
			{showFieldName ? <label className={styles.label} htmlFor={id}> {fieldName} </label> : <ScreenReaderOnly Element="label" text={fieldName} htmlFor={id} />}
			<Select instanceId={useId()} inputId={inputId} styles={customStyles} className={styles.select} options={options} {...restProps} isMulti={isMulti} isSearchable={isSearchable} components={components} data-sentry-element="Select" data-sentry-source-file="index.jsx" />
		</div>;
};
SelectComponent.propTypes = {
  showFieldName: PropTypes.bool,
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isNumeric: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number
  })).isRequired
};
export default SelectComponent;
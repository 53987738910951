import PropTypes from "prop-types";
import LinkButton from "components/controls/links/link-button";
import ChevronR from "assets/icons/chevron-r.svg";
import styles from "./component-header.module.scss";
import MarkdownParser from "components/rich-text/markdown-parser";
const ComponentHeader = ({
  title,
  description,
  link,
  hasClampedWidth = true
}) => {
  const classes = hasClampedWidth ? `${styles.wrapper} ${styles.clampedWidth}` : styles.wrapper;
  return <div className={classes} data-sentry-component="ComponentHeader" data-sentry-source-file="index.jsx"> 
			<div className={styles.titleAndLinkWrapper}>
				<div>
					<h2 className={styles.title}><MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.jsx">{title}</MarkdownParser></h2>
				</div>
				{link?.url && link?.cta ? <div className={styles.cta}>
						<LinkButton href={link.url} text={link.cta} rightIcon={<ChevronR />} />
					</div> : null}
			</div>
			<p className={styles.description}><MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.jsx">{description}</MarkdownParser></p>
		</div>;
};
ComponentHeader.propTypes = {
  description: PropTypes.string,
  hasClampedWidth: PropTypes.bool,
  link: PropTypes.shape({
    cta: PropTypes.string,
    url: PropTypes.string
  }),
  title: PropTypes.string
};
export default ComponentHeader;
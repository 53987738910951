import ContentCard from "components/cards/content-card";
import styles from "./article-card.module.scss";
import { formatFullName } from "lib/person/formatFullName";
import { commaize } from "helpers/helpers";
import AudioIcon from "assets/icons/audio.svg";
import VideoIcon from "assets/icons/video.svg";
import { ReactElement } from "react";
import { SanityPerson } from "@metmuseum/types";
import type { ContentCardProps } from "components/cards/content-card";
import { formatMetDate } from "helpers/date-time";
import { Image } from "@sanity/types";
import type { SanityArticle, ElasticPerson } from "@metmuseum/types";
const returnBadge = (type: SanityArticle["type"]): Badge => {
  switch (type) {
    case "audio":
      return {
        text: "Audio",
        icon: <AudioIcon />,
        color: "var(--color-text-header)"
      };
    case "video":
      return {
        text: "Video",
        icon: <VideoIcon />,
        color: "var(--color-text-header)"
      };
    default:
      return null;
  }
};
const ArticleCard = ({
  series,
  link,
  date,
  comingSoon,
  contributors,
  type,
  sideBySide,
  ...restProps
}: Props) => {
  const badge = returnBadge(type);
  const badgeArray = badge ? [badge] : [];
  comingSoon && badgeArray.push({
    text: "coming soon",
    color: "var(--color-blue-bright)"
  });
  return <ContentCard {...restProps} sideBySide={sideBySide} className={comingSoon ? styles.comingSoon : ""} eyebrow={series} link={comingSoon ? null : link} badges={badgeArray} data-sentry-element="ContentCard" data-sentry-component="ArticleCard" data-sentry-source-file="index.tsx">
			{contributors && <div className={styles.attribution}>
				{commaize(contributors?.map(contributor => formatFullName(contributor.person)))}
			</div>}
			{date && <div className={styles.attribution}>{formatMetDate(date)}</div>}
		</ContentCard>;
};
interface Badge {
  text: string;
  icon?: ReactElement;
  color?: string;
}
interface Props extends ContentCardProps {
  author?: string;
  comingSoon?: boolean;
  contributors?: Array<{
    person: SanityPerson | (ElasticPerson & {
      title: string;
    });
  }>;
  image: Image;
  date?: string;
  link?: string;
  series?: string;
  subTitle?: string;
  type?: SanityArticle["type"];
}
export default ArticleCard;
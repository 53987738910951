import { parseISO, format, isThisYear } from "date-fns";
import { formatInTimeZone, toDate } from "date-fns-tz";
import { NYC_IANA_TIMEZONE } from "lib/constants.mjs";


export const timeFormat: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', timeZone: NYC_IANA_TIMEZONE };
export const dateFormatFull: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric', timeZone: NYC_IANA_TIMEZONE }
export const dateFormatSansYear: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', timeZone: NYC_IANA_TIMEZONE }
export const dateFormatWithFullDay: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', timeZone: NYC_IANA_TIMEZONE }

export const isISODateString = (dateString: string) => {
	if (typeof (dateString) !== "string") {
		return false
	}
	const regEx = /^\d{4}-\d{2}-\d{2}$/;
	return dateString.match(regEx) != null;
}

export const nycTodayAsPseudoISO8601DateString = (): string => {
	return formatInTimeZone(new Date(), NYC_IANA_TIMEZONE, 'yyyy-MM-dd')
}

/* Returns a styleguide compatible string */
// WARNING: this API will change. right now it's designed to coerce ALL ISO8601 date strings into NY time
// when we migrate our Sanity data to UTC timestamps, this precaution will not be needed and become a bug instead of a feature
// you can reliably pass it a Date object though
export const formatDate = (date: Date | string, options: Intl.DateTimeFormatOptions | undefined | false, locale = "en") => {
	//  coerce ALL ISO8601 date strings into NY time
	const dateObject = typeof (date) === "string" ? newYorkizeDate(date) : date;
	return new Intl.DateTimeFormat(locale, (options || dateFormatFull)).format(dateObject)
}

// Same as above, but with very common logic to omit the year if the date is in the current year
export const formatMetDate = (date: string | Date, locale = 'en') : string => {
	const metDate = typeof (date) === "string" ? newYorkizeDate(date) : date;
	const format = isThisYear(metDate) ? dateFormatSansYear : dateFormatFull;
	return Intl.DateTimeFormat(locale, format).format(metDate)
}

/*
	Problem: ISO8601 date strings ("2023-06-27") are always meant to represent UTC time. We meant them in New York time.
	Solution: toDate from date-fns-tz gives us back UTC time with the proper offsetting
*/
export const newYorkizeDate = (datestring: string): Date | null => {
	return toDate(datestring, { timeZone: NYC_IANA_TIMEZONE }) // 2023-06-29T04:00:00.000Z | added NYC offset (4 or 5 hours) to a UTC midnight date, to reflect accurate NYC midnight
}

//Returns styleguide friendly time string.
export const formatTime = (time) => {
	const parsedTime = parseISO(time);
	return formatDate(parsedTime, timeFormat);
}

/**
 * Formats a date into 'Month Year' format (e.g., January 2021).
 * Assumes the date string is in ISO 8601 format.
 * 
 * @param {string} dateString - The ISO date string to format.
 * @returns {string | null} - The formatted date string or null if invalid.
 */
export const formatMonthYear = (dateString: string): string | null => {
	if (!dateString) return null;

	const date = toDate(dateString, { timeZone: NYC_IANA_TIMEZONE });
	if (isNaN(date.getTime())) return null;

	return format(date, 'MMMM yyyy');
};

/**
 * @remarks (fullstack-friendly!)
 * If you just pass a unix timestamp (UTC) to `new Date()`,
 * it will be formatted relative to the machine's timezone.
 * This helper ensures that it stays in the intended timezone of UTC by using
 * `toISOString()`, which is always in UTC (Z) no matter the environment
 * @example epochTimeToUTCYYYYMMDD(1728526762609) => '2024-10-10'
 */
// eslint-disable-next-line no-undef
export const epochTimeToUTCYYYYMMDD = (timestamp: EpochTimeStamp) =>
	new Date(timestamp).toISOString().split("T")[0]
